<template>
 <div>
<dynamicDetailsNormal v-if="!$store.state.user.elderModel"></dynamicDetailsNormal>
<dynamicDetailsElder v-else></dynamicDetailsElder>
 </div>
</template>

<script>
import dynamicDetailsNormal from './dynamic-details-normal.vue'
import dynamicDetailsElder from './dynamic-details-elder.vue'
 export default {
   name: '',
   components: {
dynamicDetailsNormal,
dynamicDetailsElder
   },
   data () {
     return {

     }
   },
   computed: {

   },
   created() {

   },
   mounted() {

   },
   methods: {

   }
 }
</script>

<style  scoped>

 
</style>


