<template>
  <div>
    <van-list
      v-model="replyloading"
      :finished="replyfinished"
      finished-text="暂无更多"
      @load="replyMore(item.id)"
    >
      <div v-for="item in replyList" :key="item.id" class="comment-reply">
        <span class="reply-name">{{ item.realname }}&nbsp;:</span
        ><span class="reply-content">&nbsp;{{ item.discussData }}</span>
      </div>
    </van-list>
  </div>
</template>

<script>
import { getreplyComment } from '@/api/jun-chuang'
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  name: '',
  components: {},
  data() {
    return {
      replyloading: false,
      replyfinished: false,
      replypageNo: 1,
      replypageSize: 10,
      replyList: []
    }
  },
  computed: {},
  created() {
    console.log(1)
  },
  mounted() {},
  methods: {
    async replyMore(id) {
      console.log(5)
      try {
        // 1. 请求获取数据
        const result = await getreplyComment({
          pageNo: this.replypageNo,
          pageSize: this.replypageSize,
          id: id
        })

        const list = result.result
        console.log(list)
        // 2. 把请求结果数据放到 list 数组中

        // 数组的展开操作符，它会把数组元素依次传入
        this.replyList.push(...list)
        // 判断活动时间是否在当前时间段

        // 3. 本次数据加载结束之后要把加载状态设置为结束
        this.replyloading = false

        // 4. 判断数据是否全部加载完成
        if (result.result.length) {
          // 更新获取下一页数据的时间戳
          this.replypageNo++
        } else {
          // 没有数据了，将 finished 设置为 true，不再 load 加载更多了
          this.replyfinished = true
        }
      } catch (err) {
        // 请求失败了，loading 也需要关闭
        this.replyloading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.comment-reply {
  margin-top: 9px;
  margin-bottom: 3px;
  padding-left: 10px;
  width: 280px;
  line-height: 28px;
  background: #f2f3f5;
  border-radius: 1px;
  .reply-name {
    font-size: 13px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #275b94;
  }
  .reply-content {
    font-size: 13px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #464a55;
    word-break: break-all;
  }
}
</style>
