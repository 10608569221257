<template>
  <div class="outermost">
    <layout class="layout" :hd="true" :ft="false">
      <!-- 右边按键 -->
      <div slot="rt"></div>
      <!-- 左边按键 -->
      <div slot="le" class="hd-left" @click="returnHome">
        <div></div>
        <span>动态详情</span>
      </div>
      <div class="dynamic-container" v-for="item in dynamicList" :key="item.id">
        <div class="dynamic">
          <van-cell class="user-info" center :border="false">
            <div class="profile" slot="icon">
              <img :src="item.avatar" alt="" />
            </div>

            <div slot="title" class="user-name">{{ item.realname }}</div>
            <div slot="label" class="publish-date">{{ item.createTime }}</div>
          </van-cell>
          <div class="dynamic-content">
            <p>
              {{ item.textData }}
            </p>

            <div class="dynamic-img">
              <div
                class="img"
                v-for="(src, index) in item.pictureData"
                :key="index"
              >
                <img ref="article-content" :src="src" alt="" />
              </div>
            </div>
            <div class="category">
              <p
                v-for="(related, index) in item.relatedTopicList"
                :key="index"
                @click="GotoHot(related.id, related.name)"
              >
                {{ related.name }}
              </p>
            </div>
          </div>
        </div>
        <div class="comment">
          <div class="comment-top">
            <div class="left">评论 {{ item.comments }}</div>
            <div class="right">赞 {{ item.likes }}</div>
          </div>
          <div class="underline">
            <div></div>
          </div>
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="暂无更多"
            @load="onLoad"
          >
            <div
              class="comment-wrap"
              v-for="(item, index) in commentList"
              :key="item.id"
            >
              <div class="comment-item">
                <div class="portrait">
                  <img :src="item.avatar" alt="" />
                </div>
                <div class="user-comment">
                  <div class="user-container">
                    <p class="user-name">{{ item.realname }}</p>
                    <p class="comment-date">{{ item.createTime }}</p>
                  </div>

                  <div class="comment-details">
                    <div class="text">{{ item.discussData }}</div>

                    <van-button
                      @click="Deletemessage(item, index)"
                         v-if="item.userId == userInfo.id"
                      class="btn-item"
                      icon="delete-o"
                    >
                    </van-button>
                  </div>
                  <div v-if="item.discussList != null && !item.unfoldMore">
                    <div
                      v-for="item1 in item.discussList"
                      :key="item1.id"
                      class="comment-reply"
                    >
                      <span class="reply-name">{{ item1.realname }}&nbsp;:</span
                      ><span class="reply-content"
                        >&nbsp;{{ item1.discussData }}</span
                      >
                    </div>
                  </div>
                  <replyList v-if="item.unfoldMore" :item="item"></replyList>
                  <!-- <div v-if="item.unfoldMore">
                    <van-list
                      v-model="replyloading"
                      :finished="replyfinished"
                      finished-text="暂无更多"
                      @load="replyMore(item.id)"
                    >
                      <div
                        v-for="item in replyList"
                        :key="item.id"
                        class="comment-reply"
                      >
                        <span class="reply-name"
                          >{{ item.realname }}&nbsp;:</span
                        ><span class="reply-content"
                          >&nbsp;{{ item.discussData }}</span
                        >
                      </div>
                    </van-list>
                  </div> -->
                  <div
                    class="unfoldMore"
                    v-if="item.discussList != null"
                    @click="item.unfoldMore = !item.unfoldMore"
                  >
                    {{ item.unfoldMore ? '收起' : '更多回复' }}
                  </div>
                </div>
              </div>
              <div class="comment-underline"></div>
            </div>
          </van-list>
        </div>
      </div>
    </layout>
  </div>
</template>

<script>
import layout from '@/components/layout.vue'
import { getItem, setItem } from '@/utils/auth'
import replyList from './replyList.vue'
import { Dialog } from 'vant'
import { ImagePreview } from 'vant'
import {
  getdynamicList,
  getdynamicComment,
  deleteMessage,
  getreplyComment
} from '@/api/jun-chuang'
export default {
  components: {
    layout,
    replyList
  },
  data() {
    return {
      dynamicList: [],
      commentList: [],
      loading: false,
      finished: false,
      pageNo: 1,
      pageSize: 10,
      unfoldMore: false
      // replyList: [],
      // replyloading: false,
      // replyfinished: false,
      // replypageNo: 1,
      // replypageSize: 10
    }
  },
  computed:{
  userInfo() {
      return getItem('userInfo')
    }
  },
  created() {
    this.getdynamicList(this.$route.params.id)
  },
  methods: {
    previewImage() {
      // 得到所有的 img 节点
      const articleContent = this.$refs['article-content']
      console.log(articleContent)
      // const imgs = articleContent.querySelectorAll('img')
      // console.log(imgs)
      // 获取所有 img 地址
      const images = []
      articleContent.forEach((img, index) => {
        images.push(img.src)

        // 给每个 img 注册点击事件，在处理函数中调用预览
        img.onclick = () => {
          ImagePreview({
            // 预览的图片地址数组
            images,
            // 起始位置，从 0 开始
            startPosition: index
          })
        }
      })
    },
    Deletemessage(item, index) {
      Dialog.confirm({
        message: '确认删除吗',
        width: '200px',
        confirmButtonColor: '#10955b'
      })
        .then(() => {
          deleteMessage({
            id: item.id
          }).then((res) => {
            this.commentList.splice(index, 1)
            // this.dynamicList[0].comments--
            this.getdynamicList(this.$route.params.id)
          })
        })

        .catch(() => {
          return
        })
    },
    getdynamicList(id) {
      getdynamicList({
        dId: id
      }).then((res) => {
        const list = res.records
        list.forEach((item) => {
          item.pictureData = item.pictureData.split(',')
        })
        this.dynamicList = list
      })
    },
    async onLoad() {
      try {
        const result = await getdynamicComment({
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          id: this.$route.params.id
        })
        const list = result.result
        list.forEach((item) => {
          item.unfoldMore = false
        })

        this.commentList.push(...list)
        this.loading = false
        if (list.length) {
          this.pageNo++
        } else {
          this.finished = true
        }
        // 初始化图片点击预览
        setTimeout(() => {
          this.previewImage()
        }, 0)
      } catch (err) {
        this.loading = false
      }
    },
    // async replyMore(id) {
    //   try {
    //     // 1. 请求获取数据
    //     const result = await getreplyComment({
    //       pageNo: this.replypageNo,
    //       pageSize: this.replypageSize,
    //       id: id
    //     })

    //     const list = result.result
    //     console.log(list)
    //     // 2. 把请求结果数据放到 list 数组中

    //     // 数组的展开操作符，它会把数组元素依次传入
    //     this.replyList.push(...list)
    //     // 判断活动时间是否在当前时间段

    //     // 3. 本次数据加载结束之后要把加载状态设置为结束
    //     this.replyloading = false

    //     // 4. 判断数据是否全部加载完成
    //     if (result.result.length) {
    //       // 更新获取下一页数据的时间戳
    //       this.replypageNo++
    //     } else {
    //       // 没有数据了，将 finished 设置为 true，不再 load 加载更多了
    //       this.replyfinished = true
    //     }
    //   } catch (err) {
    //     // 请求失败了，loading 也需要关闭
    //     this.replyloading = false
    //   }
    // },
    returnHome() {
     this.$router.push('/jun-chuang')
    },
    GotoHot(id, name) {
      this.$router.push({ name: 'hotDynamic', params: { id: id, name: name } })
    }
  }
}
</script>

<style lang="less" scoped>
.outermost {
  background-color: #f2f3f5;
  .layout /deep/ .main {
    padding-bottom: 0;
  }
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
    span {
      font-size: 18px;
      margin-left: 4px;
    }
  }
}
.dynamic {
  margin-bottom: 12px;
  padding: 20px 16px 10px 16px;
  background: #ffffff;
  .user-info {
    width: 295px;
    height: 39px;
    padding: 0;
    .btn-item {
      border: none;
      padding: 0;
      height: 30px;
      font-size: 13px;
      color: #868a93;
    }
  }
  .profile {
    width: 39px;
    height: 39px;
    margin-right: 13px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .user-name {
    font-size: 16px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #12151b;
    letter-spacing: 0px;
  }
  .publish-date {
    font-size: 11px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #868a93;
  }
  .dynamic-content {
    margin-top: 12px;
    overflow: hidden;
    p {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #12151b;
      line-height: 26px;
      letter-spacing: 0px;
    }
  }
  .dynamic-img {
    margin-top: 29px;
    display: flex;
    flex-flow: wrap;
    .img {
      background-color: rgb(255, 255, 255);
      margin-right: 4px;
      margin-bottom: 4px;
      width: 110px;
      height: 110px;
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  .category {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0 6px 0px;
    align-content: space-between;
    p {
      margin-bottom: 10px;
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
      flex: 0 0 31%;
      margin-right: 10px;
      box-sizing: border-box;
      border-radius: 12px;
      width: 103px;
      line-height: 24px;
      background: #f7f7f7;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: center;
      color: #464a55;
      letter-spacing: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.comment {
  background: #ffffff;
  padding: 0px 20px 20px 20px;
  .comment-top {
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      flex: 1;
      height: 20px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
      color: #12151b;
    }
    .right {
      width: 110px;
      height: 16px;
      font-size: 14px;
      font-family: Arial, Arial-Regular;
      font-weight: 400;
      text-align: RIGHT;
      color: #868a93;
      letter-spacing: 0px;
    }
  }
  .underline {
    margin-bottom: 8px;
    position: relative;
    width: 343px;
    height: 1px;
    background: #c4c4c4;
    div {
      position: absolute;
      top: -1px;
      width: 40px;
      height: 2px;
      background: #10955b;
      border-radius: 4px;
    }
  }
}
.comment-item {
  margin-top: 16px;
  display: flex;
  justify-content: flex-start;
  .portrait {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: rgb(119, 119, 119);
    img {
      width: 100%;
      height: 100%;

      border-radius: 50%;
    }
  }
  .user-comment {
    .unfoldMore {
      margin-right: 0;
      margin-left: auto;
      margin-top: 10px;
      text-align: center;
      width: 60px;
      line-height: 20px;
      background: #10955b;
      border-radius: 2em;
      color: #ffffff;
    }
    margin-left: 10px;
    width: 290px;
    overflow: hidden;
    .user-container {
      width: 290px;
      display: flex;
      justify-content: space-between;
      .user-name {
      
        height: 18px;
        font-size: 13px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: LEFT;
        color: #464a55;
      }
      .comment-date {
        font-size: 12px;
        font-family: SF Pro Display, SF Pro Display-Regular;
        font-weight: 400;
        text-align: RIGHT;
        color: #868a93;
      }
    }
    .comment-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 4px;
      white-space: pre-wrap;
      .text {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: LEFT;
        color: #12151b;
        letter-spacing: 0px;

        word-break: break-all;
        width: 290px;
      }
      .btn-item {
        border: none;
        padding: 0;
        height: 30px;
        font-size: 13px;
        color: #868a93;
      }
    }
    .comment-reply {
      margin-top: 9px;
      margin-bottom: 3px;
      padding-left: 10px;
      width: 280px;
      line-height: 28px;
      background: #f2f3f5;
      border-radius: 1px;
      .reply-name {
        font-size: 13px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: LEFT;
        color: #275b94;
      }
      .reply-content {
        font-size: 13px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: LEFT;
        color: #464a55;
        word-break: break-all;
      }
    }
  }
}
.comment-underline {
  width: 289px;
  margin-top: 16px;
  margin-left: 46px;
  border-bottom: 1px solid #c2c5cc;
}
</style>
