import request from '@/utils/request'

export function gethotList(params) {
    return request({
        url: '/lowcode/online/cgform/api/getData/8bb2e2a83b44420aa4b391d441aa03e6',
        method: 'get',
        params
    })
}
export function getdynamicList(params) {
    return request({
        url: '/lowcode/dynamic/queryPageList',
        method: 'get',
        params
    })
}
export function addLike(id) {
    return request({
        url: `/lowcode/dynamicStart/starOrCancel/${id}`,
        method: 'get',
    })
}
export function getdynamicComment(params) {
    return request({
        url: `/lowcode/dynamicDiscuss/getInfos/1/${params.id}`,
        method: 'get',
        params
    })
}
export function getreplyComment(params) {
    return request({
        url: `/lowcode/dynamicDiscuss/getInfos/2/${params.id}`,
        method: 'get',
        params
    })
}
export function addComment(data) {
    return request({
        url: '/lowcode/dynamicDiscuss/add',
        method: 'post',
        data
    })
}
export function addLikeDynamicComment(params) {
    return request({
        url: '/lowcode/dynamic/myStar',
        method: 'get',
        params
    })
}
export function deleteMessage(params) {
    return request({
        url: `/lowcode/dynamicDiscuss/del/${params.id}`,
        method: 'DELETE',
        params
    })
}
export function sendShare(params) {
    return request({
        url: `/lowcode/dynamic/share`,
        method: 'get',
        params
    })
}
